import { mobileAndTabletCheck, getParameterByName, isObject, firstIfUndefined, arrayFirst, getKey } from "@helpers/utils.js"
import Enum from "@shared/enum.js";
import EventDispatcher from "@helpers/event-dispatcher.js";
import { redeemLangObj, setupModule, unwrapObjDev } from "@helpers/redeem-lang-obj.js";
import cookieHelper from "@helpers/cookie-helper.js";

let modelClass = class Model extends EventDispatcher {
	constructor(){
		super();
		this.configLang = {};

		this.isApp = location.origin.includes('//app.') || getParameterByName('isapp') == 'true';
	}
	init(params){
		$.extend(this, params);

		this.dynamicImports = 0;

		this.lobbyVersion = __APP_VERSION__;
		
		if(typeof this.lang === 'undefined')
			this.lang = Enum.DEFAULT_LANG_VAL;

		this.hasPromise = typeof Promise !== "undefined" && Promise.toString().indexOf("[native code]") !== -1;
		this.isMobile = this.isApp || mobileAndTabletCheck();
		this.OS = this._getOS();
		this.browser = this._getBrowser();
		this.webglDetect = this._webgl_detect();
		this.webpSupported = this._canUseWebP();
		this.androidVersion = this._getAndroidVersion();

		this.defaultGCR = 100;

		this.nid = getParameterByName("nid");
		this.startingLvl1Cat = getParameterByName('openCatById');

		this.isAndroid = navigator.userAgent.toLowerCase().includes('android');
		this.isIOS = this._checkIfIOS();
		this.isWinPhone = /windows phone|wpdesktop/.test(navigator.userAgent.toLowerCase());
		this.isIE = this._checkIfBrowserIE();
		this.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
		if(typeof this.isApp !== 'boolean') this.isApp = false;

		this.unknownTextLabel = '[unknown text label]';

		Object.defineProperty(this, 'languageUnderscore', {
			get: () => (this.language || '').replace('-', '_'),
		});

		Object.defineProperty(this, 'channelId', {
			get: () => this.isApp ? 'app' : (this.isMobile ? 'mobile' : 'browser'),
		});

		Object.defineProperty(this, 'device', {
			get: () => cookieHelper.get('device_type') || (this.isMobile ? 'mobile' : 'desktop'),
		});

		// this.gridLayoutSupported = true;

		// var iOSVer = this._iOSversion();
		// if((Array.isArray(iOSVer) && iOSVer[0] <= 10 && iOSVer[1] <= 2) || this.isIE) this.gridLayoutSupported = false;

		this.maxScreenHeight = this.isMobile ? Math.max(window.innerWidth, window.innerHeight) : (window.screen ? (window.screen.height || window.innerHeight) : window.innerHeight);
		this.maxScreenWidth = this.isMobile ? Math.max(window.innerWidth, window.innerHeight) : (window.screen ? (window.screen.width || window.innerWidth) : window.innerWidth);
		
		setupModule.call(this);
	}
	pushLang(newLangs){
		for(let key in newLangs){
			this.configLang[key] = newLangs[key];
		}
	}
	isTournament(promo){
		return Boolean(promo.tournamentId || ['network-tournament', 'local-tournament'].includes(promo.type));
	}
	isUI(key){
		return this.UIID === Enum.UIIDs[key];
	}
	checkIfTablet(){
		return this.isMobile && Math.min(window.innerWidth, window.innerHeight) >= 530;
	}
	redeemShade(obj, shade){
		if(isObject(obj)) obj = obj[shade];
		if(typeof obj === 'string') obj = obj.replace('{cdn-path}', this.cdnPath);
		return obj;
	}
	redeemLangObj(){
		return redeemLangObj.apply(this, arguments);
	}
	unwrapObjDev(){
		return unwrapObjDev.apply(this, arguments);
	}
	hasSelfExlLimit(){
		return Array.isArray(this.limits) && Boolean(this.limits.find(item => item.type == Enum.Limits.Types.SELF_EXCLUSION));
	}
	getThumbnailColAmount(){
		var ww = window.innerWidth;
		var resItem = this.columnResAmount.find(item => {
			return (item.minWidth ? (item.minWidth <= ww) : true) && (item.maxWidth ? (item.maxWidth >= ww) : true);
		});
		return resItem.amount;
	}
	getTotalCredits(){
		return this.mainAccount ? (Number(this.mainAccount.cashableCredits) + Number(this.mainAccount.bonusCredits)) : 0;
	}
	notValidByReasonStatusId(){
		if(!this.userProfile) return false;
		return [201, 202, 204].includes(this.userProfile.statusReasonId);
	}
	userValid(){
		if(!this.userProfile) return false;

		return (
			this.userProfile.statusId == Enum.AccountStatusType.VALIDATED || 
			this.userProfile.statusId == Enum.AccountStatusType.PASSIVE_VALIDATED
		);
	}
	accDisabled(){
		return Boolean(this.userProfile && this.userProfile.statusId == Enum.AccountStatusType.DISABLED);
	}
	userActive(){
		return Boolean(this.userProfile && this.userProfile.statusId == Enum.AccountStatusType.ACTIVE);
	}
	canWithdraw(){
		return !this.userFlags.withdrawDisabled && !this.hasPendingWithdrawal();
	}
	canDeposit(){
		return !this.userFlags.depositDisabled;
	}
	hasDepositLimitActive(){
		var activeLimit = (this.limits || []).find(limit => {
			return new Date(limit.start) < Date.now() && limit.type == Enum.Limits.Types.DEPOSIT;
		});
		return Boolean(activeLimit);
	}
    isValidOrActive(){
    	return this.userProfile && (this.userProfile.statusId == Enum.AccountStatusType.VALIDATED || this.userProfile.statusId == Enum.AccountStatusType.ACTIVE)/* || this.userProfile.statusId == Enum.AccountStatusType.VALIDATION_REQUIRED*/;
    }
	termsAccepted(){
		return this.termsAcceptance && this.termsAcceptance.termsAccepted && this.termsAcceptance.privacyAccepted;
	}
	isDepositPassive(){
		return this.userProfile.statusId == Enum.AccountStatusType.PASSIVE || this.userProfile.statusId == Enum.AccountStatusType.PASSIVE_VALIDATED;
	}
	canPlayForMoney(){
		if(!this.logged) return true;
		return !this.userFlags.gamesDisabled;
	}
	hasBankAccount(){
		return this.userProfile.bankName && this.userProfile.accountNo && this.userProfile.SWIF && this.userProfile.IBANCode;
	}
	hasBankAccountAndValidated(){
		return this.canWithdraw() && this.hasBankAccount();
	}
	hasPendingWithdrawal(){
		if(this.mainAccount) return this.pendingPayoutEnabled && Number(this.mainAccount.withdrawRequest) > 0;
		return false;
	}
	
	isView(){
		return Array.prototype.slice.call(arguments).includes(this.view);
	}
	isNotView(){
		return !this.isView.apply(this, arguments);
	}
	getMethodConfig (id) {
		return arrayFirst(this.paymentConfig.methodList, m => m.id == id);
	}
	isPage(page){
		return this.observables.activePage == page;
	}
	getKey(){
		return getKey.apply(this, arguments);
	}
	langContainsTranslation(label, translation){
		return Boolean(this.allLangs.find(lang => this.getStr(label, lang) === translation));
	}
	/**
	 * Returns an object containing translations associated with the specified label. 
	 * @param  {String} label   The 'key' or an association related to a particular set of translations. 
	 * @param  {String} lang    Allows you to force a specific translation to be used. If not specified, the user's lang of choice will be used. 
	 * @return {String}         The translation. If an unconfigured label was inserted, the string '[unknown text label]' will be returned. 
	 *                          If a particular translation is missing, the Enum.DEFAULT_LANG_VAL will be used. 
	 */
	getStr = (function(){
		function asset(objLabel, val){
			if(typeof val !== 'string')
				return null;

			if(!objLabel.hasOwnProperty(val))
				return firstIfUndefined(objLabel, val);
			var str = this.unwrapObjDev(objLabel[val]);
			if(str) return str;
		}

		return function(label, predefLang, returnBoolIfFalse){
			if(typeof returnBoolIfFalse !== 'boolean') returnBoolIfFalse = false;

			if(!this.configLang) return false;

			label = (label + '');
			var objLabel = this.configLang[label] || this.configLang[label.toLowerCase()];
			objLabel = this.unwrapObjDev(objLabel);
			if(objLabel){
				var withPredefLang = asset.call(this, objLabel, predefLang);
				if(withPredefLang) return withPredefLang;
				
				var withLangKey = asset.call(this, objLabel, this.langKey);
				if(withLangKey) return withLangKey;

				var withLang = asset.call(this, objLabel, this.lang);
				if(withLang) return withLang;
			}

			console.warn('Unknown text label: ' + label);
			return returnBoolIfFalse ? label : this.unknownTextLabel;
		}
	}())
	getCurrency(currencyCode, _ignoreGCC){
		if(typeof _ignoreGCC !== 'boolean') _ignoreGCC = false;

		if(!this.currencies) return;
		
		if(!currencyCode){
			if(this.userProfile && this.userProfile.currency){
				return this.currencies[this.userProfile.currency];
			}

			if(isObject(this.currenciesByDomain) && !_ignoreGCC){
				return this.getCurrency(this.currenciesByDomain[this.subDomain], true);
			}
			else{
				switch(this.subDomain){
					case 'IN' : 
						return this.getCurrency('INR');
					case 'CA' : 
						return this.getCurrency('CAD');
					case 'ZA' : 
						return this.getCurrency('ZAR');
					case 'AU' : 
						return this.getCurrency('AUD');
					case 'NZ' : 
						return this.getCurrency('NZD');
					case 'ES' : 
					case 'PE' : 
						return this.getCurrency('PEN');
				}
			}
		}
		else{
			var targetVal = this.currencies[currencyCode];
			if(targetVal && (!this.isProd || targetVal.prod) && Array.isArray(targetVal.activeIfSiteId) && targetVal.activeIfSiteId.includes(this.siteId))
				return targetVal;
		}

		for(var key in this.currencies){
			var val = this.currencies[key];
			if(this.isProd && !val.prod)
				continue;

			if(Array.isArray(val.activeIfSiteId) && val.activeIfSiteId.includes(this.siteId))
				return val;
		}

		return firstIfUndefined(this.currencies, currencyCode);
	}
	formatAsCurrency(props){
		if(typeof props !== 'object'){
			props = {
				value: props,
			};
		}

		props = $.extend({
			isCredits: true,
			includeDecimals: true,
			includeSymbol: true,
			centsSeparator: null,
			thousandsSeparator: null,
			gcr: null,
			symbolName: 'symbol_native',
		}, props);

		var value = props.value;
		var isCredits = props.isCredits;
		var includeDecimals = props.includeDecimals;
		var currencyCode = props.currencyCode;
		var includeSymbol = props.includeSymbol;

		var currency = this.getCurrency(currencyCode);
		
		var centsSeparator = props.centsSeparator || currency.centsSeparator;
		var thousandsSeparator = props.thousandsSeparator || currency.thousandsSeparator;

		if(!isCredits && isNaN(value) && typeof value === 'string')
		    value = value.replace(/\./g, '').replace(centsSeparator, thousandsSeparator);

		centsSeparator = currency.centsSeparator;
		thousandsSeparator = currency.thousandsSeparator;

		value = Number(isCredits ? this.creditsToCash(value, props.gcr) : value);
		value = this.setCurrencyDecimals(value, currencyCode);

		if(currency.code === 'INR'){
			value = new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(value);
			value = value.replace(/[^0-9\.,-]+/gi, '');
		}
		else{
			var valueSplit = (value + '').split('');
			var startingPoint = valueSplit.length - 1;

			startingPoint = valueSplit.lastIndexOf('.');
			valueSplit[startingPoint] = centsSeparator;

			for(var i = startingPoint - 3; i > 0; i -= 3) valueSplit.splice(i, 0, thousandsSeparator);

			value = valueSplit.join('');
		}

		if(includeSymbol) {
			value = currency[props.symbolName] + (props.symbolName === 'code' ? ' ' : '') + value;
		}

		if(!includeDecimals){
		    var decSubstr = value.substr(value.lastIndexOf(centsSeparator), currency.decimal_digits + 1);
			var dec = Number(decSubstr.replace(/[^0-9]/g, ''));
			if(dec === 0)
				value = value.replace(decSubstr, '');
		}

		return value;
	}
	setCurrencyDecimals(value, currencyCode){
		return Number(value).toFixed(this.getCurrency(currencyCode).decimal_digits);
	}
	creditsToCash(credits, gcr){
		return Number(credits) / this.getGameCreditsRate(gcr);
	}
	cashToCredits(cash, gcr){
		return Number(cash) * this.getGameCreditsRate(gcr);
	}
	getGameCreditsRate(gcr){
		if(typeof gcr === 'number') return gcr;
		if(this.mainAccount) return this.mainAccount.gameCreditsRate;
		return this.defaultGCR;
	}
	_getAndroidVersion(ua) {
		ua = (ua || navigator.userAgent).toLowerCase(); 
		var match = ua.match(/android\s([0-9\.]*)/);
		return match ? match[1] : undefined;
	}
	setSubContext(uiSubContext){
		this.currentSubContext = uiSubContext;
	}
	getCurrContext(){
		return this.currentContext || null;
	}
	getCurrSubContext(){
		return this.currentSubContext || null;
	}
	formatDateTodayYesterday(timestamp){
		var targetDate = new Date(timestamp);
		var todayDate = new Date();
		var yesterdayDate = new Date();
		yesterdayDate.setDate(yesterdayDate.getDate() - 1);

		var label = null;

		if(targetDate.toDateString() == todayDate.toDateString())
			label = 'Today';

		if(targetDate.toDateString() == yesterdayDate.toDateString())
			label = 'Yesterday';

		if(label){
			return {
				str: this.getStr(label),
				label: label,
			};
		}

		var formattedDate = '';
		var currDate = targetDate.getDate();
		formattedDate += (currDate < 10 ? '0' + currDate : currDate) + '.';
		var currMonth = (targetDate.getMonth() + 1);
		formattedDate += (currMonth < 10 ? '0' + currMonth : currMonth) + '.';
		formattedDate += targetDate.getFullYear().toString().substr(-2) + '.';

		return {
			str: formattedDate
		};
	}
	_getOS(){
		var OSName = "Unknown OS";
		if (navigator.userAgent.indexOf("Win") != -1) OSName="Windows";
		if (navigator.userAgent.indexOf("Mac") != -1) OSName="Macintosh";
		if (navigator.userAgent.indexOf("Linux") != -1) OSName="Linux";
		if (navigator.userAgent.indexOf("Android") != -1) OSName="Android";
		if (navigator.userAgent.indexOf("like Mac") != -1) OSName = "iOS";
		return OSName;
	}
	_getBrowser(){
		var ua= navigator.userAgent, tem,
		M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
		if(/trident/i.test(M[1])){
			tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
			return 'IE '+(tem[1] || '');
		}
		if(M[1]=== 'Chrome'){
			tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
			if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
		}
		M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
		if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
		return M.join(' ');
	}
	_webgl_detect(return_context)  {
		if (!!window.WebGLRenderingContext) {
			var canvas = document.createElement("canvas"),
				 names = ["webgl", "experimental-webgl", "moz-webgl", "webkit-3d"],
			   context = false;

			for(var i=0;i<4;i++) {
				try {
					context = canvas.getContext(names[i]);
					if (context && typeof context.getParameter == "function") {
						// WebGL is enabled
						if (return_context) {
							// return WebGL object if the function's argument is present
							return {name:names[i], gl:context};
						}
						// else, return just true
						return Enum.WebGL.ENABLED;
					}
				} catch(e) {}
			}

			// WebGL is supported, but disabled
			return Enum.WebGL.DISABLED;
		}

		// WebGL not supported
		return Enum.WebGL.UNSUPPORTED;
	}
	_canUseWebP() {
		//https://bugzilla.mozilla.org/show_bug.cgi?id=1559743
		if(navigator.userAgent.toLowerCase().indexOf('firefox') > -1)
			return this._getFirefoxVersion() > 70;

		var elem = document.createElement('canvas');

		if (!!(elem.getContext && elem.getContext('2d'))) {
			// was able or not to get WebP representation
			return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
		}
		else {
			// very old browser like IE 8, canvas not supported
			return false;
		}
	}
	_getFirefoxVersion(){
		var match = window.navigator.userAgent.match(/Firefox\/([0-9]+)\./);
		return match ? parseInt(match[1]) : 0;
	}
	_checkIfBrowserIE() {
		var ua = navigator.userAgent;
		/* MSIE used to detect old browsers and Trident used to newer ones*/
		return (ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1) && !/Edge/.test(navigator.userAgent);
	}
	// _iOSversion() {
	// 	if (this._checkIfIOS()) {
	// 		// supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
	// 		var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
	// 		return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
	// 	}
	// }
	_checkIfIOS(){
		let deviceType = cookieHelper.get('device_type');
		if(typeof deviceType === 'string') return deviceType.toLowerCase().includes('ios');
		return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
	}
}

export default new modelClass();